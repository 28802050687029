/*****************************************************************************
 * UmanIT - Searchform
 *****************************************************************************/

const searchInputs = Array.from(document.querySelectorAll("[data-trigger='input-search']")),
  header = document.querySelector(".header"),
  btnsExpandSearchform = Array.from(document.querySelectorAll("[data-action='expand-searchform']")),
  btnsClose = Array.from(document.querySelectorAll("[data-action='close-searchform']"));

const mainMenuItems = Array.from(document.querySelectorAll(".main-menu__item"));
const subMenuContainers = Array.from(document.querySelectorAll("[data-target='submenu-container']"));

// Desktop et non sticky
searchInputs.forEach(searchInput => {
  searchInput.addEventListener("focus", function () {
    expandSearchform();
  });
});

searchInputs.forEach(searchInput => {
  searchInput.addEventListener("focusout", function () {
    foldSearchform();
  });
});


// Sticky
btnsExpandSearchform.forEach(btnExpandSearchform => {
  btnExpandSearchform.addEventListener("click", function () {
    expandSearchform();
  });
});

btnsClose.forEach(btnClose => {
  btnClose.addEventListener("click", function (e) {
    e.preventDefault();
    foldSearchform();
  });
});

document.addEventListener("keydown", (e) => {
  if (e.key == "Escape") {
    foldSearchform();
  }
});

/**

 */
function expandSearchform() {
  header.classList.add("search-expanded");
  mainMenuItems.forEach(mainMenuItem => {
    mainMenuItem.classList.remove("active");
  });
  subMenuContainers.forEach(subMenuContainer => {
    subMenuContainer.removeAttribute("style");
  });
}

function foldSearchform() {
  header.classList.remove("search-expanded");
}
